import React from "react";
import { price } from "../../dummydata";
import "./price.css"; // Ensure this path is correct

const PriceCard = () => {
  return (
    <div className="container213">
      {price.map((val, index) => (
        <div className="items shadow" key={index}>
          <h4>{val.name}</h4>
          <h1>
            <span></span>
            {val.price}
          </h1>
          <p>{val.desc}</p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdWt2z8hph8TwrSeRzkPFu_gcbqjmlhTWJdIHgPRiob0tgsBg/viewform"
            style={{
              textDecoration: "none",
              color: "inherit",
              marginRight: "10px",
            }}>
            <button className="outline-btn">GET STARTED</button>
          </a>
        </div>
      ))}
    </div>
  );
};

export default PriceCard;

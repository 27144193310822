import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";
// import MeetOurTeam from "./MeetOurTeam"; // Ensure the path is correct

// const CeoTeam = () => {
//   return (
//     <>
//       {/* <Back title="Choose The Right Plan" /> */}
//       <MeetOurTeam />
//     </>
//   );
// };

const Footer = () => {
  return (
    <>
      <section className="newletter">
        <div className="container flexSB">
          <div className="left row">
            <h1>Get your certificates which</h1>
            <span>Empowers student to thrive in a dynamic world</span>
          </div>
        </div>
      </section>
      <footer>
        <div className="container padding">
          <div className="box logo">
            <h1>INGRADE</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>Building bridges to brighter future!</p>

            {/* <a
              href="https://whatsapp.com"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              <i className="fab fa-whatsapp icon"></i>
            </a> */}
            <a
              href="https://www.linkedin.com/company/ingrade/"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              <i className="fab fa-linkedin icon"></i>
            </a>
            <a
              href="https://www.instagram.com/ingrade.in?igsh=ODdqYzlhMGEweTB0"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              <i className="fab fa-instagram icon"></i>
            </a>
          </div>
          {/* <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
            </ul>
          </div> */}
          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                141, Bomanhalli , Bengaluru , karnatka,<br></br> 540034
              </li>
              {/* <li>
                <i className="fa fa-phone-alt"></i>
                +91 9918002418
              </li> */}
              <li>
                <i className="fa fa-paper-plane"></i>
                ingrade.in@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2022 All rights reserved |<i className="fa fa-heart"></i>{" "}
          by Ingrade
        </p>
      </div>
    </>
  );
};

export default Footer;

import React from "react";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div className="logo">
            <div className="logohead">
              <div>
                <img
                  src="./images/logo.jpeg"
                  alt="Logo"
                  className="logo-image"
                />
              </div>
            </div>

            {/* <span></span> */}
          </div>

          <div className="social">
            <a
              href="https://wa.link/nf8xbw"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              {/* <i className="fab fa-whatsapp icon"></i> */}
            </a>
            <a
              href="https://www.linkedin.com/company/ingrade/"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              <i className="fab fa-linkedin icon"></i>
            </a>
            <a
              href="https://www.instagram.com/ingrade.in?igsh=ODdqYzlhMGEweTB0"
              style={{
                textDecoration: "none",
                color: "inherit",
                marginRight: "10px",
              }}>
              <i className="fab fa-instagram icon"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;

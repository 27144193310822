import React from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <p class="heading_text123">Best Online Education Expertise</p>
            <p class="text122">
              Join us in redefining education, where innovation dances with
              inspiration, and every lesson is a stepping stone to greatness.
              Explore a world of endless possibilities and embark on a
              transformative learning adventure
            </p>
            <div className="button">
              <button className="primary-btn">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdWt2z8hph8TwrSeRzkPFu_gcbqjmlhTWJdIHgPRiob0tgsBg/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-link">
                  <p class="text123">
                    GET STARTED NOW
                    <i className="fa fa-long-arrow-alt-right"></i>{" "}
                  </p>
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Hero;

import React from "react";
import "./courses.css";
import { online } from "../../dummydata";
import Heading from "../common/heading/Heading";

const OnlineCourses = () => {
  return (
    <>
      <section className="online">
        <div className="container">
          <Heading
            subtitle="COURSES"
            title={
              <span style={{ fontSize: "16px", margin: "10px 0" }}>
                Browse Our Online Courses
              </span>
            }
          />
          <div className="content grid2">
            {online.map((val) => (
              <div className="box" key={val.id}>
                <div className="img">
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt="" className="show" />
                </div>
                <h1 style={{ fontSize: "14px" }}>{val.courseName}</h1>
                <span style={{ fontSize: "12px" }}>{val.course}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineCourses;

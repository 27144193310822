import React from "react";
import Back from "../common/back/Back";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <Back title="" />
      <section className="contacts padding">
        <div
          className="container shadow flexSB"
          style={{ overflowX: "hidden" }}>
          <div className="right row">
            <>
              <h5 style={{ fontSize: "14px" }}>Contact us</h5>
              <p style={{ fontSize: "14px" }}>
                We're open for any suggestion or just to have a chat
              </p>
            </>

            <div className="items grid2">
              <div className="box">
                <h4>ADDRESS:</h4>
                <p>141, Bomanhalli , bengaluru , karnatka, 540034</p>
              </div>
              <div className="box">
                <h4>EMAIL:</h4>
                <p>ingrade.in@gmail.com</p>
              </div>
            </div>

            <form action="">
              <div className="flexSB">
                <input type="text" placeholder="Name" />
                <input type="email" placeholder="Email" />
              </div>
              <input type="text" placeholder="Subject" />
              <textarea cols="30" rows="10">
                Create a message here...
              </textarea>
              <button className="primary-btn">SEND MESSAGE</button>
            </form>

            <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM DRIBBBLE</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
